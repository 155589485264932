
import { defineComponent } from "vue";
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import ScheduleBarOverlayForm from "@/components/Production/ScheduleBarOverlayForm.vue";
import Card from "primevue/card";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import GanttChart from "./GanttChart.vue";
import OverlayPanel from "primevue/overlaypanel";
import dayjs from "dayjs";
import Utils from "@/utility/utils";
import { FilterMatchMode } from "primevue/api";
import { WorkOrder, OperItem, Operation } from "@/types/state/production";
import { WorkOrderObject } from "@/types/state/workOrder";
import { WorkCenter } from "@/types/workCenter";
import MultiSelect from "primevue/multiselect";
import BarOverlayForm from "./BarOverlayForm.vue";

import { cloneDeep } from "lodash";

export default defineComponent({
  components: {
    Card,
    Column,
    DataTable,
    Dropdown,
    GanttChart,
    OverlayPanel,
    ScheduleBarOverlayForm,
    MultiSelect,
    BarOverlayForm,
  },
  data() {
    return {
      orderToSchedule: null as any,
      isLoadingOrders: false,
      rowsPerPage: 10,
      totalRecords: 0,
      filters: {
        wo_id: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        part_no: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        description: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        req_date: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        rel_date: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        status: {
          value: ["N", "O", "R", "P", "S"],
          matchMode: FilterMatchMode.IN,
        },
        workCenter: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      operationSelected: {},
    };
  },
  computed: {
    ...mapGetters({
      getWorkOrderStatusItems: "workorder/getWorkOrderStatusItems",
      getSelectedWorkOrders: "workorder/getSelectedWorkOrders",
      getSelectedScheduleGroup: "workorder/getSelectedScheduleGroup",
      getPage: "workorder/getPage",
      itemsPerPage: "workorder/getItemsPerPage",
      getSortBy: "workorder/getSortBy",
      getSortOrder: "workorder/getSortOrder",
      getSchedulingGroupItems: "woControl/getSchedulingGroupItems",
      getWorkCenterItems: "workCenters/getWorkCenterItems",
      getScheduledWorkOrders: "workorder/getScheduledWorkOrders",
      getSchedulingFromDate: "workorder/getSchedulingFromDate",
      getSchedulingToDate: "workorder/getSchedulingToDate",
      getUnscheduledWorkOrders: "workorder/getUnscheduledWorkOrders",
    }),
    hasMultipleOperations(): boolean {
      return this.getOrders.some(
        (workOrder) =>
          workOrder.oper_items.filter((item: OperItem) =>
            this.activeSelectedWorkCenters.includes(item.work_center),
          ).length > 1,
      );
    },
    getOrders(): Array<WorkOrder> {
      const unScheduledSelected = this.getSelectedWorkOrders
        .filter((order: WorkOrderObject) =>
          this.hasUnscheduledOperations(order.record),
        )
        .map((order: WorkOrderObject) => order.record);

      const activeIds = this.getSelectedWorkOrders
        .filter(
          (order: WorkOrderObject) =>
            !this.hasAllUnscheduledOperations(order.record),
        )
        .map((order: WorkOrderObject) => order.record.wo_id);

      const filteredUnscheduled = this.getUnscheduledWorkOrders.filter(
        (order: WorkOrder) => !activeIds.includes(order.wo_id),
      );

      const unscheduledIds = this.getUnscheduledWorkOrders.map(
        (order: WorkOrder) => order.wo_id,
      );

      // Case where the API return a work order with a scheduled operation but also has unscheduled operations
      const scheduledWorkOrderWithUnscheduledOperations =
        this.getScheduledWorkOrders.filter((workOrder: WorkOrder) => {
          return (
            this.hasUnscheduledOperations(workOrder) &&
            !activeIds.includes(workOrder.wo_id)
          );
        });

      return [
        ...filteredUnscheduled,
        ...unScheduledSelected,
        ...scheduledWorkOrderWithUnscheduledOperations,
      ];
    },
    getActiveWorkCenters(): Array<WorkCenter> {
      return this.getWorkCenterItems.filter(
        (center: WorkCenter) => center.allow_schedule === "Y",
      );
    },
    getSortOrderValue(): number {
      return this.getSortOrder === "ASC" ? 1 : -1;
    },
    getSortByValue(): string {
      return this.getSortBy.replace(".", "_");
    },
    workOrderStatusListFormated(): Array<{ id: string; label: string }> {
      return Object.keys(this.getWorkOrderStatusItems).map((key) => ({
        id: key,
        label: this.getWorkOrderStatusItems[key],
      }));
    },
    filteredWorkCenterList(): Array<WorkCenter> {
      if (this.getSelectedScheduleGroup && this.getSchedulingGroupItems) {
        const selectedScheduleGroup = this.getSchedulingGroupItems?.find(
          (group: any) =>
            group.scheduling_group === this.getSelectedScheduleGroup,
        );
        const workCenters: WorkCenter[] = [];

        selectedScheduleGroup?.scheduling_workcenter_items.forEach(
          (item: any) => {
            const workCenter = this.getActiveWorkCenters.find(
              (center: WorkCenter) =>
                center.work_center === item.scheduling_workcenter,
            );
            if (workCenter) {
              workCenters.push(workCenter);
            }
          },
        );
        return workCenters;
      }
      return [];
    },
    activeSelectedWorkCenters(): Array<string> {
      return this.getSchedulingGroupItems
        ?.find(
          (group: any) =>
            group.scheduling_group === this.getSelectedScheduleGroup,
        )
        ?.scheduling_workcenter_items.map(
          (item: any) => item.scheduling_workcenter,
        )
        .filter((item: any) => item);
    },
    showScheduleGroupDropdown(): boolean {
      return this.getSchedulingGroupItems?.length > 0;
    },
  },
  async created() {
    await this.fetchWorkOrderControl();
    store.commit("workorder/SET_RANGE_START", 1);
    store.commit("workorder/SET_RANGE_END", this.rowsPerPage);
    await this.fetchWorkCenters();
    store.commit("workorder/SET_FILTER_VALUES", {
      wo_id: {
        value: "",
        matchMode: "contains",
      },
      part_no: {
        value: "",
        matchMode: "contains",
      },
      description: {
        value: "",
        matchMode: "contains",
      },
      req_date: {
        value: "",
        matchMode: "equals",
      },
      rel_date: {
        value: "",
        matchMode: "equals",
      },
      status: {
        value: ["N", "O", "R", "P", "S"],
        matchMode: "in",
      },
      workCenter: {
        value: "",
        matchMode: "contains",
      },
    });
    this.handleFetchWorkOrder();

    // Only fetch scheduled work orders if nothing is currently in
    if (this.getSelectedWorkOrders.length > 0) {
      this.handleFetchScheduledWorkOrders();
    }
  },
  methods: {
    ...mapActions({
      fetchUnscheduledWorkOrders: "workorder/fetchUnscheduledWorkOrders",
      clearOrderToAdd: "workorder/clearOrderToAdd",
      updateSelectedWorkOrder: "workorder/updateSelectedWorkOrder",
      setSelectedScheduleGroup: "workorder/setSelectedScheduleGroup",
      addNotification: "notification/add",
      fetchControls: "control/fetchControl",
      fetchWorkOrderControl: "woControl/fetchWorkOrderControl",
      fetchWorkCenters: "workCenters/fetchWorkCenters",
      clearSelectedWorkOrders: "workorder/clearSelectedWorkOrders",
      setOrderToAdd: "workorder/setOrderToAdd",
      fetchScheduledWorkOrders: "workorder/fetchScheduledWorkOrders",
    }),
    rowClass(event: any): string {
      const inProgress = this.getSelectedWorkOrders.find(
        (order: WorkOrderObject) => {
          return order.record.wo_id === event.wo_id;
        },
      );

      if (inProgress) {
        return "yellow-background";
      } else {
        return "";
      }
    },
    handleFetchScheduledWorkOrders() {
      const startDate = dayjs(this.getSchedulingFromDate).format("MM-DD-YYYY");
      const endDate = dayjs(this.getSchedulingToDate).format("MM-DD-YYYY");
      const fitleredWCIDs = this.getSchedulingGroupItems
        ?.find(
          (group: any) =>
            group.scheduling_group === this.getSelectedScheduleGroup,
        )
        ?.scheduling_workcenter_items.map(
          (item: any) => item.scheduling_workcenter,
        )
        .filter((item: any) => item);
      this.fetchScheduledWorkOrders({
        Correls: "so_cust_name",
        Workcenters: fitleredWCIDs,
        OperationStartDate: startDate,
        OperationCompDate: endDate,
      });
    },
    hasUnscheduledOperations(workOrder: WorkOrder) {
      return workOrder.oper_items
        .filter((operation: OperItem) =>
          this.activeSelectedWorkCenters.includes(operation.work_center),
        )
        .some(
          (operation: OperItem) =>
            !operation.oper_comp_time || !operation.oper_start_time,
        );
    },
    hasAllUnscheduledOperations(workOrder: WorkOrder) {
      return workOrder.oper_items
        .filter((operation: OperItem) =>
          this.activeSelectedWorkCenters.includes(operation.work_center),
        )
        .every(
          (operation: OperItem) =>
            !operation.oper_comp_time || !operation.oper_start_time,
        );
    },
    validOperations(workOrder: WorkOrder) {
      const selectedWorkOrders = this.getSelectedWorkOrders.find(
        (order: WorkOrderObject) => order.record.wo_id === workOrder.wo_id,
      );
      if (selectedWorkOrders) {
        return selectedWorkOrders.record.oper_items.filter(
          (operation: OperItem) =>
            !operation.oper_start_time &&
            !operation.oper_comp_time &&
            this.activeSelectedWorkCenters.includes(operation.work_center),
        );
      } else {
        return workOrder.oper_items.filter(
          (operation: OperItem) =>
            !operation.oper_comp_time &&
            !operation.oper_start_time &&
            this.activeSelectedWorkCenters.includes(operation.work_center),
        );
      }
    },
    handleFetchWorkOrder() {
      this.isLoadingOrders = true;
      const fitleredWCIDs = this.getSchedulingGroupItems
        ?.find(
          (group: any) =>
            group.scheduling_group === this.getSelectedScheduleGroup,
        )
        ?.scheduling_workcenter_items.map(
          (item: any) => item.scheduling_workcenter,
        )
        .filter((item: any) => item);
      this.fetchUnscheduledWorkOrders({
        Correls: "so_cust_name",
        ScheduledWorkcenter: true,
        UnscheduledWorkOrders: true,
        Workcenters: fitleredWCIDs,
      })
        .then((resp) => {
          this.totalRecords = parseFloat(resp.total_records_found) || 0;
        })
        .finally(() => {
          this.isLoadingOrders = false;
        });
    },
    statusColor(itemStatus: string) {
      return "#BBB";
    },
    sortData(event: any) {
      store.commit("workorder/SET_SORT_BY", event.sortField.replace("_", "."));
      const sortOrder = event.sortOrder === -1 ? "DEC" : "ASC";
      store.commit("workorder/SET_SORT_ORDER", sortOrder);
      this.handleFetchWorkOrder();
    },
    rowClicked(event: any) {
      this.orderToSchedule = cloneDeep(event.data);
      (this.$refs as any).addfromtablepopover.toggle(event.originalEvent);
    },
    operationClick(event: any, workOrder: any, operation: any) {
      event.stopPropagation();
      this.orderToSchedule = cloneDeep(workOrder);
      const newOperation = new Operation();
      newOperation.initFromOperItem(operation);

      this.operationSelected = {
        startDate: new Date(),
        endDate: new Date(
          dayjs(event.datetime)
            .add(newOperation.totalRunTime, "hours")
            .toDate(),
        ),
        oper: operation.oper,
        isGeneric: false,
        workCenter: operation.work_center,
        barLabel: operation.operation_desc,
        modified: false,
      };
      (this.$refs as any).addoperationfromtablepopover.toggle(event);
    },
    addOperationToSchedule(event: any, workOrder: any) {
      (this.$refs as any).addoperationfromtablepopover.hide();
      const oldRecord = cloneDeep(workOrder);
      const record = cloneDeep(workOrder);

      const operationToEdit = record.oper_items.find(
        (operation: OperItem) => operation.oper === event.oper,
      );

      operationToEdit.oper_start_date = dayjs(event.startDate).format(
        "YYYY-MM-DD",
      );
      operationToEdit.oper_start_time = dayjs(event.startDate).format("HH:mm");
      operationToEdit.oper_comp_date = dayjs(event.endDate).format(
        "YYYY-MM-DD",
      );
      operationToEdit.oper_comp_time = dayjs(event.endDate).format("HH:mm");
      operationToEdit.work_center = event.workCenter;
      operationToEdit.operation_desc = event.operationDescription;

      const scheduledWo = this.getSelectedWorkOrders.find(
        (order: WorkOrderObject) => order.record.wo_id === workOrder.wo_id,
      );

      if (scheduledWo) {
        this.updateSelectedWorkOrder({ record: record });
      } else {
        this.updateSelectedWorkOrder({ record: record, oldRecord });
      }
    },
    handleFilter(event: any) {
      store.commit("workorder/NEXT_PAGE", 1);
      store.commit("workorder/SET_RANGE_START", 1);
      store.commit("workorder/SET_RANGE_END", this.rowsPerPage);
      store.commit("workorder/SET_FILTER_VALUES", event.filters);
      this.handleFetchWorkOrder();
    },
    handleOnPage(event: any) {
      store.commit("workorder/NEXT_PAGE", event.page + 1);
      store.commit("workorder/SET_RANGE_START", event.first + 1);
      store.commit("workorder/SET_RANGE_END", event.first + event.rows);
      this.handleFetchWorkOrder();

      (this.$refs.productionSchedulingTable as any).$forceUpdate();
    },
    itemDragStart(order: any, operation: any) {
      this.setOrderToAdd({
        workOrder: cloneDeep(order),
        operation: cloneDeep(operation),
      });
    },
    itemDragEnd() {
      this.clearOrderToAdd();
    },
    canOperationBeScheduled(operation: OperItem) {
      return (
        operation.oper &&
        !operation.oper.includes("-") &&
        this.filteredWorkCenterList.some(
          (center: WorkCenter) => center.work_center === operation.work_center,
        )
      );
    },
    onScheduleOrder(event: any) {
      (this.$refs as any).addfromtablepopover.hide();
      this.orderToSchedule = cloneDeep(this.orderToSchedule);
      const oldRecord = cloneDeep(this.orderToSchedule);

      this.orderToSchedule.oper_items.forEach(
        (operation: OperItem, index: number) => {
          if (this.canOperationBeScheduled(operation)) {
            operation.oper_start_date = dayjs(event.startDate).format(
              "YYYY-MM-DD",
            );
            operation.oper_start_time = dayjs(event.startDate)
              .add(index, "hours")
              .format("HH:mm");
            operation.oper_comp_date = dayjs(event.endDate).format(
              "YYYY-MM-DD",
            );
            operation.oper_comp_time = dayjs(event.endDate)
              .add(index + 1, "hours")
              .format("HH:mm");
            operation.work_center = event.workCenter;
            operation.operation_desc = event.operationDescription;
          } else {
            this.addNotification({
              type: "error",
              message: `Operation ${operation.oper} cannot be scheduled in the selected work center.`,
            });
          }
        },
      );
      this.updateSelectedWorkOrder({ oldRecord, record: this.orderToSchedule });
    },
    onScheduleGroupChange(event: any) {
      const selectedScheduleGroup = this.getSchedulingGroupItems.find(
        (group: any) => group.scheduling_group === event.value,
      );
      this.setSelectedScheduleGroup(selectedScheduleGroup.scheduling_group);
      this.handleFetchWorkOrder();
      this.clearSelectedWorkOrders();
    },
    dotColor(workOrder: WorkOrder) {
      const workCenterIndex = this.filteredWorkCenterList.findIndex(
        (center) =>
          workOrder.oper_items[0] &&
          center.work_center === workOrder.oper_items[0].work_center,
      );
      if (workCenterIndex >= 0) {
        return Utils.getColorByIndex(workCenterIndex);
      }
      return "#BBB";
    },
  },
});
